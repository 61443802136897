/* eslint-disable */
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling
} from 'devextreme-vue/data-grid'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'
import {
  ref,
  onMounted,
  reactive
} from 'vue'

export default {
  name: 'TableFinishedProcessingDetail',
  components: {
    // DxCheckBox,
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling
  },
  props: ['jadwalId', 'dataHeader'],
  setup(props: any) {
    const dataSource = ref([])
    const {
      jadwalId,
      dataHeader
    } = reactive(props)
    const autoExpandAll = ref(false)
    const sumTunai = ref(0)
    const sumNonTunai = ref(0)
    const totalReceived = ref(0)
    const descriptionTableFinished = ref(null)
    const getAllTable = async () => {
      const response = await deliveryPendingUseCase.getAll({
        expand: '&$expand=Manifest($select=NoManifest,Id,TransJadwalHdId), AgenAsal($select=Id,Nama), JenisPembayaran($select=Kode,Nama,Id)',
        select: '&$select=Resi,JenisPembayaranId,KategoriBarang,TipePengiriman,Id,KeteranganPembayaran,TransJadwalHdId,GrandTotal',
        filter: `Manifestid ne null and TransJadwalHdId eq ${jadwalId}`,
        // top: 10,
        // skip: 0
      })
      dataSource.value = response.result.map((x: any) => {
        return {
          Id: x.Id,
          Agent: x.AgenAsal.Nama,
          Manifest: x.Manifest.NoManifest,
          Resi: x.Resi,
          Tunai: x.JenisPembayaran.Kode.toLowerCase() === 'tunai' ? `Rp. ${Number(x.GrandTotal).toLocaleString('id-ID')}` : '-',
          NonTunai: x.JenisPembayaran.Kode.toLowerCase() !== 'tunai' ? `Rp. ${Number(x.GrandTotal).toLocaleString('id-ID')}` : '-',
          NumTunai: x.JenisPembayaran.Kode.toLowerCase() === 'tunai' ? x.GrandTotal : 0,
          NumNonTunai: x.JenisPembayaran.Kode.toLowerCase() !== 'tunai' ? x.GrandTotal : 0,
          Keterangan: x.KeteranganPembayaran
        }
      })
      dataSource.value.forEach((element: any) => {
        sumTunai.value += element.NumTunai
        sumNonTunai.value += element.NumNonTunai
      })
      if (dataHeader.IsClose) {
        totalReceived.value = dataHeader.UangDiterima
        descriptionTableFinished.value = dataHeader.KeteranganClose
      } else {
        totalReceived.value = sumTunai.value + sumNonTunai.value
      }
      // console.log('response', tunai.value)
    }
    onMounted(() => {
      // console.log('dataHeader', dataHeader, jadwalId)
      getAllTable()
    })
    return {
      // dummyData
      dataSource,
      autoExpandAll,
      sumTunai,
      sumNonTunai,
      totalReceived,
      dataHeader,
      descriptionTableFinished
    }
  },
  // watch: {
  //   dataHeader: {
  //     immediate: true,
  //     handler(newVal: any, oldVal: any) {
  //       console.log('on watch', newVal, oldVal)
  //     }
  //   }
  // }
}