import TableFinishedProcessingDetail from '@/views/cashier/detail/TableFinishedProcessingDetail.vue'
import {
  deliveryManifestUseCase,
  transScheduleBusUseCase
} from '@/domain/usecase'
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import moment from 'moment'
import {
  useRoute,
  useRouter
} from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

export default {
  components: {
    TableFinishedProcessingDetail
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const dataHeader = ref({}) as any
    const showTableFinished = ref(false)
    const refTableFinished = ref()
    const decryptData = () => encryptDecriptMethods.decrypt(route.params.id)
    const getHeader = async () => {
      const hasilDec = await decryptData()
      const response = await deliveryManifestUseCase.getOneDetail(Number(hasilDec), {
        expand: '&$expand=TipeBus($select=Kode)',
        select: '&$select=Id,KodeJadwal,Rit,TanggalAktif,TanggalNonAktif,IsClose,UangDiterima,KeteranganClose'
      })
      if (!response.error) {
        dataHeader.value = await response.result.value
        showTableFinished.value = true
      } else {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      }
    }

    const checkResponse = (response: any) => {
      // console.log('response after put', response)
      if (!response.error) {
        $toast.add({
          severity: 'success',
          summary: response.result.title ?? response.result.Title,
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        router.push({
          name: 'manifest-revenue'
        })
      } else {
        $toast.add({
          severity: 'error',
          // summary: response.result.title,
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      }
    }

    const storeData = async () => {
      const formData = {
        Id: Number(decryptData()),
        UangDiterima: refTableFinished.value.totalReceived,
        Keterangan: refTableFinished.value.descriptionTableFinished
      }
      const response = await transScheduleBusUseCase.closeSchedule(Number(decryptData()), formData)
      checkResponse(response)
    }

    const prosesPendapatan = () => {
      // console.log('ini ref', refTableFinished.value.totalReceived)
      $confirm.require({
        header: 'Proses Pendapatan',
        message: 'Apakah anda yakin ingin melakukan proses pendapatan, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          storeData()
        }
      })
    }

    onMounted(() => {
      decryptData()
      getHeader()
    })
    return {
      dataHeader,
      moment,
      route,
      decryptData,
      prosesPendapatan,
      refTableFinished,
      showTableFinished
    }
  }
}
